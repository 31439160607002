import React from 'react';
import Layout from 'components/Layout';
import { graphql } from 'gatsby';
import { Container as Grid, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';

const Body = styled.section`
  position: relative;
  z-index: 1;
  padding: 160px 0;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 24px;

  ${media.lg`
    flex-direction: row;
    align-items: center;
  `}
`;

const Headline = styled.h1`
  ${media.xs`
    font-size: 36px;
    overflow-wrap: anywhere;
  `}
`;

const LegalPage = ({ data, location }) => {
  const {
    data: body,
    alternate_languages: altLangs,
    lang: pageLang,
  } = data.legalPage.nodes[0];
  const { text_body, headline, meta_title, meta_description, meta_image } =
    body;
  return (
    <Layout
      pageLang={pageLang}
      altLangs={altLangs}
      location={location}
      seo={{
        title: meta_title,
        description: meta_description,
        image: meta_image?.url,
      }}
    >
      <Grid>
        <Body>
          <Header>
            <Headline>{headline.text}</Headline>
          </Header>
          <div dangerouslySetInnerHTML={{ __html: text_body.html }} />
        </Body>
      </Grid>
    </Layout>
  );
};

export default LegalPage;

export const pageQuery = graphql`
  query LegalPageQuery($locale: String!, $id: String!) {
    legalPage: allPrismicLegalPage(
      filter: { lang: { eq: $locale }, id: { eq: $id } }
    ) {
      nodes {
        id
        lang
        tags
        alternate_languages {
          lang
          uid
        }
        data {
          meta_title
          meta_description
          meta_image {
            url
          }
          headline {
            text
          }
          text_body {
            html
          }
        }
      }
    }
  }
`;
